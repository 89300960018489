import * as Sentry from '@sentry/gatsby';
import type {ErrorEvent, TransactionEvent} from '@sentry/types';

// These values would be coming from sentry if we do an actual integration; giving dummy values for now
export const HOST = process.env.GATSBY_DOMAIN;

const projectIdMap = {'maestro-ui': '1', 'zeus-ui': '2'};
type ProjectName = keyof typeof projectIdMap;
export const knownProjectIds = Object.values(projectIdMap);
export const OPS_STREAM_HTTP_URL = 'https://default.stream.cribl-operations.cloud:10080/cribl/_bulk';

const filterLocalEvent = (event: ErrorEvent | TransactionEvent, logToConsole = true) => {
  if (event.environment === 'cribl-local.cloud') {
    //don't send if it's on local environment, log to console instead
    if (logToConsole) {
      console.log(event);
    }
    return null;
  }
  return event;
};

// These lists are from https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#decluttering-sentry
const commonIgnoreRules = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  // Avast extension error
  '_avast_submit'
];

const commonDenyUrl = [
  // Google Adsense
  /pagead\/js/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
];

const projectId = projectIdMap['zeus-ui'];
const dsn = `http://dummy@${HOST}/${projectId}`;
Sentry.setTag('project', 'zeus-ui' as ProjectName);
Sentry.init({
  dsn: dsn,
  tunnel: process.env.GATSBY_TUNNEL,
  environment: process.env.GATSBY_DOMAIN,
  tracesSampleRate: process.env.GATSBY_TRANSACTION_SAMPLE_RATE
    ? Number(process.env.GATSBY_TRANSACTION_SAMPLE_RATE)
    : 0.2,
  beforeSend(event: ErrorEvent) {
    return filterLocalEvent(event);
  },
  beforeSendTransaction(event: TransactionEvent) {
    return filterLocalEvent(event, false); // don't log transaction to console
  },
  ignoreErrors: [...commonIgnoreRules, 'Non-Error exception captured', 'ResizeObserver loop limit exceeded'],
  denyUrls: commonDenyUrl
});
